import axios from "axios";

/**
 * Helper function to toggle the value in an array (add or remove based on existence)
 * @param {Array} dataList - The list of data to update.
 * @param {any} value - The value to toggle in the data list.
 * @returns {Array} Updated list with the value added or removed.
 */
const toggleValue = (dataList, value) =>
  dataList.includes(value)
    ? dataList.filter((item) => item !== value)
    : [...dataList, value];

/**
 * Fetches data from the API.
 * @returns {Promise<Object>} The data fetched from the API.
 * @throws {Error} If the API call fails.
 */
const getData = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_SAP_DATA);
    console.log("response.data", response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw new Error("Failed to fetch data from API");
  }
};

/**
 * Adds a filter for a specific key and value to the filter data.
 * @param {Object} params - The filter parameters.
 * @param {string} params.key_ - The key of the filter.
 * @param {any} params.value - The value to filter by.
 * @param {Object} params.filterData - The current filter data to update.
 * @returns {Object} The updated filter data.
 */
const addFilter = ({ key_, value, filterData }) => {
  const updatedFilterData = { ...filterData };
  updatedFilterData[key_] = toggleValue(filterData[key_] || [], value);
  return updatedFilterData;
};

/**
 * Filters the asset data based on the selected asset type.
 * @param {Object} params - The asset filter parameters.
 * @param {string} params.value - The asset type to filter by.
 * @param {Array} params.assetData - The list of asset data.
 * @returns {Array} The filtered list of asset data.
 */
const assetTypeFilter = ({ value, assetData }) => toggleValue(assetData, value);

/**
 * Filters the sync data based on the selected sync status.
 * @param {Object} params - The sync filter parameters.
 * @param {string} params.value - The sync status to filter by.
 * @param {Array} params.filterSync - The list of sync statuses to filter.
 * @returns {Array} The filtered list of sync statuses.
 */
const syncFilter = ({ value, filterSync }) => toggleValue(filterSync, value);

/**
 * Filters the error data based on asset type and error type.
 * @param {Array} results - The list of data to filter.
 * @param {string} assetType - The selected asset type to filter by.
 * @param {string} errorType - The selected error type to filter by.
 * @returns {Object} An object containing the filtered results and the total number of records.
 */
const filterError = (results, assetType, errorType) => {
  // Base filter to match asset type
  const baseFilter = (res) => res.technical_object_type_description === assetType;

  // Define error conditions based on error type
  const errorConditions = {
    'No corresponding GIS record found': (res) =>
      baseFilter(res) && res['Summary Concat'] === 'No record in GIS',
    'Object Type mismatch': (res) =>
      baseFilter(res) && res['Summary Concat'] === 'Object type mismatch',
  };

  const totalRecords = results.filter(baseFilter).length;
  const checkFalse = results.filter(errorConditions[errorType] || (() => false));
  const checkTrue = results.filter(
    (res) =>
      baseFilter(res) &&
      !res['Summary Concat']
  );

  return { checkFalse, checkTrue, totalRecords };
};

/**
 * Fetches asset type data and filters it based on error type.
 * @param {string} filterErrorType - The error type to filter by.
 * @returns {Promise<Array>} A list of asset types with error data.
 */
const getAssetType = async (filterErrorType) => {
  const results = await getData();
  localStorage.setItem('filterErrorType', filterErrorType); // Store selected error type
  const distinctTypes = [...new Set(results.map((item) => item.technical_object_type_description))];

  return distinctTypes.map((type, index) => {
    const { checkFalse, checkTrue, totalRecords } = filterError(results, type, filterErrorType);
    return {
      type,
      falsedata: checkFalse,
      false: checkFalse.length,
      true: checkTrue.length,
      totalRecords: totalRecords,
      objectId: index,
    };
  });
};

/**
 * Fetches and returns a filtered list of error counts.
 * @returns {Promise<Object>} An object with error counts for different types.
 */
const getErrorList = async () => {
  const results = await getData();

  // Calculate error counts by iterating through results
  const errorCounts = results.reduce(
    (acc, res) => {
      if (res['Summary Concat'] === 'No record in GIS') acc.no_record++;
      if (res['Summary Concat'] === 'Object type mismatch') acc.mismatch++;
      if (res['Summary Concat'] === 'Asset name mismatch') acc.asset_name_mismatch++;
      if (res['Summary Concat'] === 'Operational status mismatch') acc.op_status_mismatch++;
      if (res['Summary Concat'] === 'Duplicate SAP key') acc.duplicate_sap_keys++;
      return acc;
    },
    { no_record: 0, mismatch: 0, asset_name_mismatch: 0, op_status_mismatch: 0, duplicate_sap_keys: 0 }
  );

  return errorCounts;
};

/**
 * Error type filter function (unused but structured for future use).
 * @param {string} errortype - The error type to filter.
 * @returns {string} The error type.
 */
const errorTypeFilter = (errortype) => errortype;

/**
 * Fetches a filtered list of asset type data for a specific asset type ID.
 * @param {number} id - The index representing the asset type.
 * @returns {Promise<Array>} A list of filtered asset data based on the asset type.
 */
const getAssetTypeTable = async (id) => {
  try {
    const filterErrorType = localStorage.getItem('filterErrorType'); // Retrieve the filter error type
    const results = await getData(); // Fetch the data
    const distinctTypes = [
      ...new Set(results.map((item) => item.technical_object_type_description)),
    ]; // Get unique asset types

    const sourceLayer = distinctTypes[id]; // Get the selected asset type using the provided ID
    const { checkFalse } = filterError(results, sourceLayer, filterErrorType); // Filter the results based on the selected asset type and error type
    return checkFalse; // Return the filtered list
  } catch (error) {
    console.error("Error fetching asset type table data:", error);
    throw new Error("Failed to fetch asset type table data");
  }
};

// Service Object - Aggregates all SAP-related functions
const sapService = {
  getData,
  getAssetType,
  getErrorList,
  getAssetTypeTable,
  assetTypeFilter,
  addFilter,
  syncFilter,
  errorTypeFilter,
};

export default sapService;
