import axios from "axios";

/**
 * Fetches GIS data from the specified API endpoint.
 * @returns {Promise<Object[]>} - The fetched data as JSON.
 */
const getData = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_GIS_DATA);
    console.log('data', response.data)
    return response.data;
  } catch (error) {
    console.error("Error fetching GIS data:", error);
    return []; // Return an empty array to prevent breaking the app
  }
};

/**
 * Toggles a value in an array (adds it if missing, removes it if present).
 * @param {Array} dataList - The list of values.
 * @param {*} value - The value to toggle.
 * @returns {Array} - Updated list.
 */
const toggleFilter = (dataList, value) =>
  dataList.includes(value) ? dataList.filter((item) => item !== value) : [...dataList, value];

/**
 * Filters errors based on asset type and error type.
 * @param {Array} results - The dataset to filter.
 * @param {string} assetType - The asset type to filter.
 * @param {string} errorType - The error type to filter.
 * @returns {Object} - Object containing matching items.
 */
const filterError = (results, assetType, errorType) => {
  const errorConditions = {
    "No record in SAP": (res) => res.source_layer === assetType && res["Summary_Concat"] === "No record in SAP",
    "Object Type mismatch": (res) => res.source_layer === assetType && res["Summary_Concat"] === "Object type mismatch",
    "SAP Floc missing in GIS": (res) => res.source_layer === assetType && res["Summary_Concat"] === "SAP Floc missing in GIS",
    "Duplicate SAP keys in GIS": (res) => res.source_layer === assetType && res["Summary_Concat"] === "Duplicate_SAP_key",
  };

  const checkFalse = results.filter(errorConditions[errorType] || (() => false));
  const checkTrue = results.filter((res) => res.source_layer === assetType && !res["Summary_Concat"]);

  return { checkTrue, checkFalse };
};

/**
 * Updates filter data by toggling a key-value pair.
 * @param {Object} params - The filter parameters.
 * @param {string} params.key_ - The key to update.
 * @param {*} params.value - The value to toggle.
 * @param {Object} params.filterData - Existing filter data.
 * @returns {Object} - Updated filter data.
 */
const addFilter = ({ key_, value, filterData }) => ({
  ...filterData,
  [key_]: toggleFilter(filterData[key_] || [], value),
});

/**
 * Retrieves filtered asset data based on a selected asset type.
 * @param {number} id - The index of the asset type.
 * @returns {Promise<Object[]>} - The filtered asset data.
 */
const getAssetTypeTable = async (id) => {
  const results = await getData();
  const filterErrorType = localStorage.getItem("filterErrorType");
  const distinctTypes = [...new Set(results.map((item) => item.source_layer))];
  const sourceLayer = distinctTypes[id];
  return filterError(results, sourceLayer, filterErrorType).checkFalse;
};

/**
 * Filters an asset type by toggling its value.
 * @param {Object} params - The filter parameters.
 * @param {*} params.value - The asset type value to toggle.
 * @param {Array} params.assetData - The existing asset data.
 * @returns {Array} - Updated asset data.
 */
const assetTypeFilter = ({ value, assetData }) => toggleFilter(assetData, value);

/**
 * Synchronizes the current filter status.
 * @param {*} status - The filter status.
 * @returns {*} - The status.
 */
const syncFilter = (status) => status;

/**
 * Retrieves a list of asset types along with their error counts.
 * @param {string} filterErrorType - The type of error to filter.
 * @returns {Promise<Object[]>} - The list of asset types with error statistics.
 */
const getAssetType = async (filterErrorType) => {
  const results = await getData();
  const distinctTypes = [...new Set(results.map((item) => item.source_layer))];

  localStorage.setItem("filterErrorType", filterErrorType);

  return distinctTypes.map((type, index) => {
    const { checkTrue, checkFalse } = filterError(results, type, filterErrorType);
    return {
      type,
      falsedata: checkFalse,
      false: checkFalse.length,
      true: checkTrue.length,
      objectId: index,
    };
  });
};

/**
 * Counts occurrences of each error type in the dataset.
 * @returns {Promise<Object>} - An object containing counts of each error type.
 */
const getErrorList = async () => {
  const results = await getData();

  return results.reduce((acc, res) => {
    const errorType = res["Summary_Concat"]; // Extract error type directly

    if (errorType) {
      acc[errorType] = (acc[errorType] || 0) + 1;
    }
    return acc;
  }, {});
};

/**
 * Returns the selected error type.
 * @param {*} errortype - The error type.
 * @returns {*} - The error type.
 */
const errorTypeFilter = (errortype) => errortype;

// **Exporting Service Object**
const dataService = {
  getData,
  getAssetType,
  getErrorList,
  getAssetTypeTable,
  assetTypeFilter,
  addFilter,
  syncFilter,
  errorTypeFilter,
};

export default dataService;
