import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getErrorList, reset } from "../features/data/dataSlice";

const ErrorList = () => {
    const dispatch = useDispatch();
    const { errorList, loading } = useSelector((state) => state.data);

    // Fetch error list on mount
    useEffect(() => {
        dispatch(getErrorList());
        return () => dispatch(reset());
    }, [dispatch]);

    /**
     * Calculates the total errors dynamically.
     * @returns {number} Total error count.
     */
    const getTotalErrors = () =>
        Object.values(errorList || {}).reduce((sum, count) => sum + count, 0);

    return (
        <div className="col-2" style={{ marginLeft: "5px" }}>
            {/* Header Section */}
            <div className="row">
                <div className="col board" style={{ padding: "33px 20px", border: "none" }}>
                    <p style={{ color: "white" }}>N</p>
                </div>
            </div>

            {/* Table Section */}
            <div className="row table-row mt-4 mb-4">
                <div className="col-12 bg-blue">
                    <h3>Error List</h3>
                </div>

                {loading ? (
                    <p className="text-center">Loading errors...</p>
                ) : errorList && Object.keys(errorList).length > 0 ? (
                    <table className="table top-border-blue table-bordered">
                        <thead className="bg-blue">
                            <tr>
                                <th className="bg-blue" scope="col">Error Type</th>
                                <th className="bg-blue" scope="col">Assets</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(errorList).map(([key, count]) => (
                                <tr key={key}>
                                    <th scope="row">{key.replace(/_/g, " ")}</th>
                                    <td>{count || 0}</td>
                                </tr>
                            ))}
                            <tr>
                                <th scope="row">Total</th>
                                <td>{getTotalErrors()}</td>
                            </tr>
                        </tbody>
                    </table>
                ) : (
                    <p className="text-center">No errors found.</p>
                )}
            </div>
        </div>
    );
};

export default ErrorList;
