import axios from "axios";


  // GIS and SAP Data Fetchers
  const getGis = async () =>
    {
      const response = await axios.get(process.env.REACT_APP_GIS_DATA);
      return response.data;
    }
    
  
  const getSap = async () =>
  {
    const response = await axios.get(process.env.REACT_APP_SAP_DATA);
    return response.data;
  }
  
  // Common Error Filtering Logic
  const calculateErrorMetrics = (results, conditions, checkTrueCondition) => {
    const checkTrue = results.filter(checkTrueCondition).length;
  
    const in_gis_but_missing_in_sap_condition = {
      "No record in SAP": (res) =>
        res['Summary_Concat']=== 'No record in SAP',

      "SAP Floc missing in GIS": (res) =>
        res['Summary_Concat']=== 'SAP Floc missing in GIS',
    };

    const assets_with_errors_conditions = {
      "Object Type mismatch": (res) =>
        res['Tech Object Type mismatch'] &&
        res['Summary_Concat']!== 'No record in SAP' &&
        res['Summary_Concat']!== 'SAP Floc missing in GIS',
  
    
      "Duplicate SAP keys in GIS": (res) =>
        res['Summary_Concat'] === 'Duplicate_SAP_key'

    }

    const in_sap_but_missing_in_gis_condition = {
      "No record in SAP": conditions['No record in SAP']
    };


    const in_sap_but_missing_in_gis = Object.values(in_sap_but_missing_in_gis_condition)
    .map((condition) => results.filter(condition).length)
    .reduce((sum, count) => sum + count, 0);

    const assets_with_errors = Object.values(assets_with_errors_conditions)
    .map((condition) => results.filter(condition).length)
    .reduce((sum, count) => sum + count, 0);

    const in_gis_but_missing_in_sap = Object.values(in_gis_but_missing_in_sap_condition)
    .map((condition) => results.filter(condition).length)
    .reduce((sum, count) => sum + count, 0);

    const totalErrors = Object.values(conditions)
      .map((condition) => results.filter(condition).length)
      .reduce((sum, count) => sum + count, 0);
  
    const totalRecords = results.length;
    const percentageErrors = ((totalErrors / totalRecords) * 100).toFixed(1);
  
    return { totalRecords, totalErrors, percentageErrors, in_gis_but_missing_in_sap,assets_with_errors, in_sap_but_missing_in_gis };
  };
  
  // GIS Error Filtering
  const filterErrorGis = (results) => {
    const conditions = {
      "No record in SAP": (res) =>
        res['Summary_Concat']
    };
  
    const checkTrueCondition = (res) =>
      !res['Summary_Concat'];
  
    return calculateErrorMetrics(results, conditions, checkTrueCondition);
  };
  
  // SAP Error Filtering
  const filterErrorSap = (results) => {
    const conditions = {
      "No record in SAP": (res) =>
        res['Summary Concat'] === 'No record in GIS',
  
      "Object Type mismatch": (res) =>
        res['Summary Concat'] === 'Object type mismatch',
    };
  
    const checkTrueCondition = (res) =>
      !res['Summary Concat'];
  
    return calculateErrorMetrics(results, conditions, checkTrueCondition);
  };
  
  // GIS and SAP Data Handlers
  const getGisData = async () => {
    const results = await getGis();
    return filterErrorGis(results);
  };
  
  const getSapData = async () => {
    const results = await getSap();
    return filterErrorSap(results);
  };
  
  // Data Service Object
  const overviewService = {
    getSapData,
    getGisData,
  };
  
  export default overviewService;
  