import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { errorTypeFilter, assetTypeFilter, syncFilter } from '../features/data/dataSlice';

function Filter() {
    const dispatch = useDispatch();
    
    const [assets] = useState([
        'SL_SWR_PUMP_STN_SYNC', 'SL_SWR_TRMN_PLNT_SYNC', 'SL_WTR_PUMP_STN_SYNC', 'vSL_WTR_RSRV_SYNC',
        'SL_WTR_ZONE_MNG_STN_SYNC', 'SL_WTNK_BLD_DPTS_WRKS_SYNC', 'SL_WTNK_BLD_WS_STAFF_HSNG_SYNC',
        'SL_WTNK_BULK_BRHL_SYNC', 'SL_WTNK_BULK_DAMS_SYNC', 'SL_WTNK_BULK_PUMP_STN_SYNC', 'SL_WTNK_BULK_RSRV_SYNC',
        'SL_WTNK_BULK_TRTM_PLNT_SYNC', 'SL_WTNK_SPR_RSRV_SYNC', 'SL_WTNK_TE_PUMP_STN_SYNC', 'SL_WTNK_TE_RSRV_SYNC'
    ]);

    const errorTypeOptions = [
        'SAP Floc missing in GIS', 'No record in SAP', 'Object Type mismatch', 
        'Asset name mismatch', 'Operational status mismatch', 'Duplicate SAP keys in GIS'
    ];

    const { filterSync, filterErrorType, filterDataAsset, isSuccess, message } = useSelector((state) => state.data);

    // Map of asset names for cleaner code
    const assetNameMap = {
        'SL_SWR_PUMP_STN_SYNC': 'Sewer - Pump Stations',
        'SL_SWR_TRMN_PLNT_SYNC': 'Sewer - Treatment Plants',
        'SL_WTNK_BLD_DPTS_WRKS_SYNC': 'WS Buildings - Depots and Workshops',
        'SL_WTNK_BLD_WS_STAFF_HSNG_SYNC': 'WS Buildings - Staff Housing',
        'SL_WTNK_BULK_BRHL_SYNC': 'Bulk Water - Boreholes',
        'SL_WTNK_BULK_DAMS_SYNC': 'Bulk Water - Dams',
        'SL_WTNK_BULK_PUMP_STN_SYNC': 'Bulk Water - Pump Stations',
        'SL_WTNK_BULK_RSRV_SYNC': 'Bulk Water - Reservoirs',
        'SL_WTNK_BULK_TRTM_PLNT_SYNC': 'Bulk Water - Treatment Plants',
        'SL_WTNK_SPR_RSRV_SYNC': 'Spring Water - Reservoir',
        'SL_WTNK_TE_PUMP_STN_SYNC': 'Treated Effluent - Pump Stations',
        'SL_WTNK_TE_RSRV_SYNC': 'Treated Effluent - Reservoirs',
        'SL_WTR_PUMP_STN_SYNC': 'Water - Pump Stations',
        'SL_WTR_ZONE_MNG_STN_SYNC': 'Water - Zone Management Stations',
        'SL_WTR_RSRV_SYNC': 'Water - Reservoirs'
    };

    const handleToggleActive = (e) => {
        const element = document.getElementById(e.target.id);
        element.classList.toggle('active');
    };

    return (
        <div className="col-md-3 mt-2 filter">
            <div className="date-of-download">
                <p>03 / 05 / 2024</p>
                <label>Date of download</label>
            </div>

            <div className="filter-by-asset">
                <div className="label mb-2">Filter - By Asset Type</div>
                {
                    assets.map((asset, index) => (
                        <div
                            className="item"
                            onClick={(e) => {
                                dispatch(assetTypeFilter({ value: asset, assetData: filterDataAsset }));
                                handleToggleActive(e);
                            }}
                            id={`asset-${index}`}
                            key={index}
                        >
                            {assetNameMap[asset]}
                        </div>
                    ))
                }

                <div className="label mt-3 mb-2">Filter - By Error Type</div>
                {
                    errorTypeOptions.map((errorType, index) => (
                        <div
                            key={index}
                            id={errorType.replace(/\s+/g, '-').toLowerCase()}
                            onClick={() => dispatch(errorTypeFilter(errorType))}
                            className={filterErrorType === errorType ? 'item active' : 'item'}
                        >
                            {errorType}
                        </div>
                    ))
                }

                {/* The following block for sync filter is commented out, you can re-enable it if needed */}
                {/* 
                <div className="label mt-3 mb-2">Filter - By Sync Status</div>
                <div
                    id="sap-synced"
                    onClick={() => dispatch(filterSync === 'SAP Synced' ? syncFilter('') : syncFilter('SAP Synced'))}
                    className={filterSync === 'SAP Synced' ? 'item mt-3 active' : 'item'}
                >
                    SAP Synced
                </div>
                <div
                    id="yes"
                    onClick={() => dispatch(filterSync === 'Yes' ? syncFilter('') : syncFilter('Yes'))}
                    className={filterSync === 'Yes' ? 'item active' : 'item'}
                >
                    Yes
                </div> 
                */}
            </div>
        </div>
    );
}

export default Filter;
